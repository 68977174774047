<template>
  <div>
    <v-container
      fluid
      v-if="warning.length == 0 || warning == 'No results found'"
    >
      <v-row v-if="isEmployeeActive || !$store.getters.isWebAdmin">
        <v-col cols="12" md="5">
          <SearchBar
            @search="onSearch($event)"
            searchLabel="Search by notification..."
            ref="searchBar"
          />
        </v-col>
        <v-col align="right" class="grow">
          <v-tooltip bottom v-if="allOff">
            <template v-slot:activator="{ on }">
              <v-btn
                v-blur
                class="msaBlue white--text"
                v-on="on"
                @click="toggleAll(true)"
              >
                <v-icon> mdi-bell-outline </v-icon>
              </v-btn>
            </template>
            <span>Turn All Notifications On</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-btn
                v-blur
                class="red white--text"
                v-on="on"
                @click="toggleAll(false)"
              >
                <v-icon> mdi-bell-off-outline </v-icon>
              </v-btn>
            </template>
            <span>Turn All Notifications Off</span>
          </v-tooltip>
        </v-col>
        <v-col class="shrink">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-blur
                v-on="on"
                :disabled="disableSave"
                class="msaBlue white--text"
                @click="save()"
                data-testid="save"
              >
                <v-icon> mdi-content-save-outline </v-icon>
              </v-btn>
            </template>
            <span>save</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels
            transition="fade-transition"
            v-model="expanded"
            multiple
            active-class="msaBlue white--text"
          >
            <v-expansion-panel
              v-for="(report, index) in edited"
              :key="index"
              class="my-2"
            >
              <v-expansion-panel-header
                hide-actions
                v-slot="{ open }"
                :style="headerStyles(index)"
              >
                <v-row no-gutters align="center">
                  <v-col class="shrink" :cols="isMobile ? 1 : false">
                    <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                    <v-icon v-else>mdi-plus-thick</v-icon>
                  </v-col>
                  <v-col class="grow pl-4 font-weight-medium">
                    {{ report.permissionTypeLabel }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card tile>
                  <v-simple-table
                    v-for="(category, ind) in report.categories"
                    :key="ind"
                  >
                    <thead>
                      <tr class="headerTextGrey--text lightGrey">
                        <th class="text-left">
                          {{ category.category }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(notification, i) in category.notifications"
                        :key="i"
                      >
                        <td>
                          <v-row align="center">
                            <v-col class="shrink">
                              <v-switch
                                v-model="notification.enabled"
                                inset
                                hide-details
                                dense
                                class="mt-0"
                              ></v-switch>
                            </v-col>
                            <v-col>
                              {{ notification.label }}
                            </v-col>
                            <v-col class="grow" align="center">
                              <v-btn-toggle
                                v-if="
                                  notification.enabled &&
                                  // exclude monthly report notifications
                                  notification.notificationTypeId != 21 &&
                                  notification.notificationTypeId != 53 &&
                                  notification.notificationTypeId != 56
                                "
                                v-model="notification.intervalTypeId"
                                mandatory
                                active-class="msaBlue white--text"
                              >
                                <v-btn :value="1" small width="90px">
                                  Daily
                                </v-btn>
                                <v-btn :value="2" small width="90px">
                                  Weekly
                                </v-btn>
                                <v-btn :value="3" small width="90px">
                                  Monthly
                                </v-btn>
                              </v-btn-toggle>
                            </v-col>
                            <v-col cols="2">
                              <v-select
                                v-if="
                                  notification.intervalTypeId == 3 &&
                                  notification.enabled &&
                                  notification.notificationTypeId != 56
                                "
                                v-model="notification.selectedDay"
                                :items="monthDays"
                                label="Day"
                                hide-details
                                dense
                                outlined
                                chips
                              ></v-select>
                              <v-select
                                v-if="
                                  notification.intervalTypeId == 2 &&
                                  notification.enabled &&
                                  notification.notificationTypeId != 56
                                "
                                v-model="notification.selectedDay"
                                :items="weekDays"
                                item-text="text"
                                item-value="index"
                                dense
                                label="Weekday"
                                hide-details
                                outlined
                                chips
                              ></v-select>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row
        v-if="!isEmployeeActive && !isNewEmployee && $store.getters.isWebAdmin"
      >
        <v-col align="center">
          Inactive user does not have any notifications.
        </v-col>
      </v-row>
      <v-row v-if="!isEmployeeActive && isNewEmployee">
        <v-col align="center" v-if="isNewEmployee">
          Unsaved new user does not have any notifications.
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="warning.length > 0">
      <v-row>
        <v-col align="center">
          {{ warning }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'UserNotificationsPage',
  components: {
    SearchBar,
  },
  computed: {
    headerStyles() {
      return (index) =>
        this.expanded.findIndex((i) => i == index) == -1
          ? {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            }
          : {};
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    disableSave() {
      return JSON.stringify(this.edited) == JSON.stringify(this.reports);
    },
    isEmployeeActive() {
      return this.$store.getters.selectedUser.status;
    },
    isNewEmployee() {
      return this.$store.getters.selectedUser.id == 0;
    },
    allOff() {
      return this.reports.every((report) =>
        report.categories.every((category) =>
          category.notifications.every((notification) => !notification.enabled),
        ),
      );
    },
    userId() {
      return this.$route.name == 'UserProfileNotifications'
        ? this.$store.getters.user.id
        : this.$route.params.id;
    },
  },
  data() {
    return {
      search: '',
      reports: [],
      expanded: [],
      edited: [],
      monthDays: [],
      weekDays: [],
      warning: '',
    };
  },
  methods: {
    getNotificationSettings() {
      const url = 'get-user-notifications?format=json';

      const params = {
        id: this.userId,
        loaderText: 'Loading...',
        search: this.search,
      };

      if (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      ) {
        params.companyId = this.$route.params.companyId;
      }

      return this.$axios
        .post(url, params)
        .then((r) => {
          this.reports = r.data.notifications;
          this.organizeData();
          this.expandAll();
          if (this.$route.name != 'UserProfileNotifications') {
            this.$store.commit('updateSelectedUser', {
              ...r.data.user,
              status: r.data.user.status == 'Active' ? 1 : 0,
            });
          }
        })
        .catch((error) => {
          if (error.response.data) {
            this.warning = error.response.data;
          }
        });
    },
    organizeData() {
      this.fixBooleans();
      this.edited = JSON.parse(JSON.stringify(this.reports));
      this.fillDataForDaySelection();
    },
    // Make sure booleans are true/false instead of 0/1 so the save button will be enabled/disabled properly
    fixBooleans() {
      this.reports.forEach((item) => {
        item.categories.forEach((category) => {
          category.notifications.forEach((i) => {
            i.enabled = i.enabled ? true : false;
          });
        });
      });
    },
    // fill weekdays and month days for the selection
    fillDataForDaySelection() {
      const locale =
        typeof navigator.language == 'string'
          ? navigator.language
          : navigator.language[0];
      this.getWeekDays(locale);
      this.monthDays = [...Array(32).keys()];
      this.monthDays.shift();
    },
    getWeekDays(locale) {
      let baseDate = new Date(Date.UTC(2017, 0, 2)); // just a random Monday
      for (let i = 1; i <= 7; i++) {
        this.weekDays.push({
          index: i,
          text: baseDate.toLocaleDateString(locale, {
            weekday: 'long',
          }),
        });
        baseDate.setDate(baseDate.getDate() + 1);
      }
    },
    expandAll() {
      this.expanded = this.edited.map((_, i) => i);
    },
    getRouteQueries() {
      this.search = this.$helpers.getRouteQueryForString(
        this.$route.query,
        'search',
      );

      this.getNotificationSettings();
    },
    onSearch(event) {
      if (!this.disableSave) {
        this.$root.showMessage(
          'You have unsaved changes.',
          'Continue to search will lost current changes. Are you sure?',
          () => {
            this.search = event;
            this.updateUrl();
          },
          () => false,
          'Confirm',
          'Cancel',
        );
      } else {
        this.search = event;
        this.updateUrl();
      }
    },
    updateUrl() {
      const params = {};

      if (this.search != '') {
        params.search = this.search;
      }

      this.$router
        .push({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    save() {
      const url = 'save-user-notifications?format=json';
      const params = {
        id: this.userId,
        loaderText: 'Loading...',
        items: this.edited,
      };

      if (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      ) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      this.$axios
        .post(url, params)
        .then(() => (this.reports = JSON.parse(JSON.stringify(this.edited))))
        .catch((error) => error);
    },
    toggleAll(value) {
      this.$root.showMessage(
        'Warning',
        `This will turn ${
          value ? 'on' : 'off'
        } all notifications. Are you sure?`,
        () => {
          this.search = '';
          this.getNotificationSettings().then(() => {
            this.edited.forEach((item) => {
              item.categories.forEach((category) => {
                category.notifications.forEach((i) => {
                  i.enabled = value;
                });
              });
            });
            this.save();
          });
        },
        () => false,
        'Confirm',
        'Cancel',
      );
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.isEmployeeActive || this.isEmployeeActive === undefined) {
        if (this.search != '') {
          this.$refs.searchBar.search = this.search;
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
